// Entry point for the build script in your package.json
import "@hotwired/turbo-rails";
import "trix";
import "@rails/actiontext";
import "./controllers";
import * as Sentry from "@sentry/react";

if (window._rails_env !== "development") {
  Sentry.init({
    environment: window._rails_env,
    release: window._sentry_release,
    dsn: "https://e3671c78c30c8422d514f5f1173166c4@o4507294624710656.ingest.us.sentry.io/4507294628708352",
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.captureConsoleIntegration({ levels: ["warn", "error"] }),
      Sentry.replayIntegration(),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["localhost", /^https:\/\/[\w.-]*\.ignite-reading\.com/],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}
